define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["de", {
    "callBar": {
      "actions": {
        "clearAnnotations": "Zeichnen löschen",
        "hangUp": "Auflegen",
        "increaseZoom": "Zoom erhöhen",
        "more": "Mehr",
        "participants": "Teilnehmer",
        "pauseRecording": "Aufzeichnung pausieren",
        "recording": "Aufnahme",
        "reduceZoom": "Zoom verringern",
        "resumeRecording": "Aufzeichnung fortsetzen",
        "settings": "Einstellungen",
        "startCamera": "Kamera anschalten",
        "startFlashlight": "Licht anschalten",
        "startMicrophone": "Mikrofon aktivieren",
        "startRecording": "Aufzeichnung starten",
        "startScreencast": "Screencast starten",
        "stopCamera": "Kamera ausschalten",
        "stopFlashlight": "Licht ausschalten",
        "stopMicrophone": "Mikrofon stummschalten",
        "stopScreencast": "Screencast stoppen",
        "swapVideo": "Video tauschen",
        "switchCamera": "Kamera umschalten",
        "videoZoom": "Zoom",
        "zoom": "Zoom"
      },
      "annotations": {
        "color": "Farbe",
        "label": "Anmerkungen",
        "mode": "Modus",
        "shape": "Form",
        "size": "Größe"
      },
      "bottomSheet": {
        "localControls": "Lokal",
        "remoteControls": "Fernsteuerung"
      },
      "participants": {
        "backAll": "Zurück zur Teilnehmerübersicht",
        "kickAll": "Alle entfernen",
        "label": "Teilnehmer",
        "muteAll": "Alle stumm schalten",
        "selectSwitchRHost": "Wählen Sie einen Agenten als Gastgeber aus.",
        "selectSwitchRole": "Wählen Sie einen Teilnehmer als Gast aus."
      },
      "recording": {
        "recordingActive": "Die Besprechung wird aufgezeichnet.",
        "recordingPaused": "Die Besprechungsaufzeichnung ist pausiert."
      },
      "remoteControls": "Fern-</br>steuerung",
      "remoteDeviceInfo": {
        "battery": "Batterie",
        "device": "Gerät",
        "label": "Gast-</br> Geräteinformationen",
        "network": "Netzwerk"
      },
      "settings": {
        "audioSettings": "Audio-Einstellungen",
        "backCamera": "Rückkamera",
        "camera": "Kamera",
        "frontCamera": "Frontkamera",
        "label": "Einstellungen",
        "liveTranslation": {
          "enabled": "Angeschaltet",
          "label": "Live-Übersetzung",
          "language": "Sprache",
          "subtitleSize": "Untertitel Größe"
        },
        "microphone": "Mikrofon",
        "speaker": "Sprecher",
        "videoSettings": "Video-Einstellungen",
        "virtualBackground": {
          "label": "Virtueller Hintergrund",
          "mode": "Modus"
        }
      }
    },
    "chat": {
      "notifications": {
        "current": "Aktuelles Meeting",
        "newMessage": "Neue Nachricht erhalten",
        "now": "Jetzt",
        "turnOff": "Nachrichtenbenachrichtigungen aktiviert",
        "turnOn": "Nachrichtenbenachrichtigungen deaktiviert"
      },
      "welcome": "Willkommen im Chatroom"
    },
    "close": "Schließen",
    "components": {
      "signature": {
        "clear": "Löschen"
      }
    },
    "extensionInstall": {
      "installLabel": "Installieren",
      "instructions": {
        "Chrome": "Bitte installieren Sie die Sightcall-Erweiterung aus dem Chrome Store.",
        "Edge": "Bitte installieren Sie die Sightcall-Erweiterung aus dem Edge Store.",
        "Firefox": "Bitte installieren Sie die Sightcall-Erweiterung aus dem Firefox Store."
      },
      "logoAlt": {
        "Chrome": "Chrome logo",
        "Edge": "Edge logo",
        "Firefox": "Firefox logo"
      },
      "retryBtn": "Erneut versuchen",
      "retryText": "Sie können auf den Link unten klicken, um zu versuchen, den Anruf neu zu starten",
      "title": {
        "Chrome": "Chrome Extension benötigt",
        "Edge": "Edge Extension benötigt",
        "Firefox": "Firefox Extension benötigt"
      }
    },
    "format": {
      "date": "dd.MM.yyyy",
      "hour": "HH:mm"
    },
    "imageEditor": {
      "arrow": "Pfeil",
      "brightness": "Helligkeit",
      "brushWidth": "Pinselbreite",
      "circle": "Kreis",
      "color": "Farbe",
      "contrast": "Kontrast",
      "crop": "Schneiden",
      "fill": "Ausfüllen",
      "fontFamily": "Font Family",
      "helper": {
        "movePicture": {
          "android": "2 Finger",
          "ios": "2 Finger",
          "mac": "Wahl+Klick",
          "windows": "Alt+Klick"
        },
        "movePictureLabel": "Bild verschieben",
        "zoom": {
          "android": "Schaltflächen (Pinch-In/Out-Gesten werden nicht unterstützt)",
          "ios": "Schaltflächen (Pinch-In/Out-Gesten werden nicht unterstützt)",
          "mac": "Tasten + Mausrad / Trackpad",
          "windows": "Tasten + Mausrad / Trackpad"
        },
        "zoomLabel": "Vergrößern/Verkleinern"
      },
      "rectangle": "Rechteck",
      "rotate": "Drehen",
      "saturation": "Sättigung",
      "stroke": "Strich",
      "strokeWidth": "Strichbreite"
    },
    "newVersionModal": {
      "continueLabel": "Weiter",
      "info": "\"Aktualisierung\" klicken um die neue Version zu aktivieren.",
      "message": "\"Weiter\" klicken, um zu ignorieren.",
      "title": "Neue Version verfügbar",
      "updateLabel": "Aktualisierung",
      "warning": "Alle Ihre Daten werden gelöscht."
    },
    "open": "Öffnen",
    "pluginInstall": {
      "Mac OS": {
        "Chrome": {
          "imgSrc": "/assets/images/plugin-install/chrome-1.png",
          "step1": "Öffnen Sie die '<em>'RTCCdriver.pkg'</em>' Datei unten links in Ihrem Browser, um die Installation zu starten.",
          "title": "Plugin-Download in Ihrem Browser gestartet"
        },
        "Firefox": {
          "imgSrc": "/assets/images/plugin-install/firefox-3.png",
          "step1": "Wählen Sie '<em>'Öffnen'</em>' mit und klicken Sie auf '<em>'OK'</em>'.",
          "title": "Herunterladen des Plugins gestartet"
        },
        "Safari": {
          "imgSrc": "/assets/images/plugin-install/safari-3.png",
          "step1": "Klicken Sie auf das '<em>'Download'</em>'-Icon oben rechts im Haupttab des Browsers.",
          "step2": "Klicken Sie auf die Datei '<em>'RTCC_Plugin.pkg'</em>', um die Installation zu starten.",
          "title": "Herunterladen des Plugins gestartet"
        }
      },
      "Windows": {
        "Chrome": {
          "imgSrc": "/assets/images/plugin-install/chrome-2.png",
          "step1": "Öffnen Sie die '<em>'RTCCdriver.exe'</em>' Datei unten links in Ihrem Browser, um die Installation zu starten.",
          "title": "Plugin-Download in Ihrem Browser gestartet"
        },
        "Edge": {
          "imgSrc": "/assets/images/plugin-install/ie-1.png",
          "step1": "Klicken Sie unten in der Dialogbox auf '<em>'ausführen'</em>', um die Installation zu starten.",
          "title": "RTCC-Plugin herunterladen und installieren"
        },
        "Firefox": {
          "imgSrc": "/assets/images/plugin-install/firefox-5.png",
          "step1": "Klicken Sie auf '<em>'Datei speichern'</em>', um den Download des Plugins zu starten.",
          "step2": "Klicken Sie auf das '<em>'Download-Icon'</em>' <img class=\"icon-download\" src=\"/assets/images/plugin-install/firefox-4.png\" />, um die Plugin-Datei einblenden zu lassen.",
          "step3": "Führen Sie einen Doppelklick auf '<em>'RTCC Plugin.msi'</em>' aus, um die Installation des Plugins zu starten.",
          "title": "RTCC-Plugin downloaden und installieren"
        },
        "IE": {
          "imgSrc": "/assets/images/plugin-install/ie-1.png",
          "step1": "Klicken Sie unten in der Dialogbox auf '<em>'ausführen'</em>', um die Installation zu starten.",
          "title": "RTCC-Plugin herunterladen und installieren"
        }
      },
      "followInstallationStep": "Folgen Sie den Installations-Schritten.",
      "retryBtn": "Erneut versuchen",
      "retryText": "Sobald das Plugin installiert ist und/oder wenn das Herunterladen nicht gestartet wurde, können Sie auf die folgende Schaltfläche klicken",
      "step1": "Schritt 1:",
      "step2": "Schritt 2:",
      "step3": "Schritt 3:",
      "step4": "Schritt 4:"
    },
    "request": {
      "acceptLabel": "Akzeptieren",
      "cancelLabel": "Abbrechen",
      "canceledMessage": "Die Anfrage wurde storniert.",
      "closeLabel": "Schließen",
      "coreDump": {
        "title": "coreDump in die Zwischenablage kopieren?"
      },
      "declineLabel": "Ablehnen",
      "joinMeetingRoomCanceled": "{displayName} lehnte seine anfrage ab, für {meetingTitle} in den warteraum zu kommen",
      "joiningMeetingRoom": "{displayName} betrat für {meetingTitle} das Wartezimmer",
      "sharePicture": {
        "title": "Bild von der Fotogalerie teilen?"
      },
      "startCamera": {
        "notifySent": "Ihre Anfrage zum Starten der Kamera wurde gesendet",
        "title": "Kamera aktivieren?"
      },
      "startGeolocation": {
        "title": "Ihren Standort teilen?"
      },
      "startMicro": {
        "notifySent": "Ihre Anfrage an Start Micro wurde gesendet",
        "title": "Mikrofon aktivieren?"
      },
      "startRemoteControl": {
        "notifyDecline": "Ihr Antrag auf Fernsteuerung wurde abgelehnt",
        "notifySent": "Ihre Anfrage zur Fernsteuerung wurde gesendet",
        "notifyUnavailable": "Dieser Benutzer kann nicht aus der Ferne gesteuert werden",
        "title": "Akzeptieren Sie die Fernsteuerung Ihres Geräts ?"
      },
      "startShare": {
        "notifySent": "Ihre Anfrage zum Starten von Screencast wurde gesendet",
        "title": "Bildschirm teilen?"
      },
      "switchCamera": {
        "frontTitle": "Auf Rückkamera wechseln?",
        "notifySent": "Ihre Anfrage zum Kamerawechsel wurde gesendet",
        "rearTitle": "Auf Frontkamera wechseln?"
      },
      "switchHost": {
        "notifyDecline": "Ihre Anfrage zum Hostwechsel wurde abgelehnt",
        "notifySent": "Ihre Anfrage zum Hostwechsel wurde gesendet",
        "title": "Die Rolle zum Gastgeber wechseln ?"
      },
      "switchRole": {
        "notifyDecline": "Ihre Anfrage zum Rollenwechsel wurde abgelehnt",
        "notifySent": "Ihre Anfrage zum Rollenwechsel wurde gesendet",
        "title": "Die Rolle zum Gast wechseln ?"
      }
    },
    "resumeModal": {
      "expiry": "Verfällt am: '{{ formatDate \"${_expiresAt}\" \"dd.MM.yyyy HH:mm\"}}'",
      "lastUpdate": "Letzte Aktualisierung am: '{{ formatDate \"${_lastUpdate}\" \"dd.MM.yyyy HH:mm\"}}'",
      "message": "Sie haben diesen Flow bereits gestartet",
      "restartLabel": "Neustarten",
      "resumeLabel": "Weiterfahren",
      "title": "Flow bereits gestartet"
    },
    "rtcc": {
      "videoDisabledLabel": "Video deaktiviert"
    },
    "services": {
      "barcodeScanner": {
        "cancelLabel": "Abbrechen",
        "confirmLabel": "Bestätigen"
      }
    },
    "today": "Heute",
    "tooltips": {
      "copied": "Kopiert!"
    },
    "vs_screens": {
      "app_not_found": {
        "text": "Bitte gehen Sie zum App-Store, um die neueste Version zum Ausführen von SightCall Digital Flows herunterzuladen.",
        "title": "Sie benötigen die Visual Support App"
      },
      "application_update_required": {
        "text": "Bitte gehen Sie zum App-Store, um die neueste Version zum Ausführen von SightCall Digital Flows herunterzuladen.",
        "title": "Ihre Visual Support App muss aktualisiert werden"
      },
      "digital_flow_not_found": {
        "text": "Es tut uns leid, der von Ihnen angefragte Digital Flow kann nicht gefunden werden.",
        "title": "Digital Flow Not Found"
      },
      "digital_flow_not_valid": {
        "title": "Digital Flow definition ist ungültig"
      },
      "incompatible_device": {},
      "insufficient_credits": {
        "text": "Es tut uns leid, dieser Link ist gültig, aber wir haben einige Probleme bei der Bereitstellung. Bitte melden Sie den Fehlercode <b>402 - SDF</b> an die Person, die Ihnen den Link geschickt hat.",
        "title": "Dieser Link kann nicht geöffnet werden"
      },
      "page_not_found": {
        "text": "Es tut uns leid, die von Ihnen angefragte Seite kann nicht gefunden werden.",
        "title": "Seite nicht gefunden"
      },
      "powered_by": "Powered By",
      "system_info_timeout": {
        "text": "The app took too long to send System information (timeout). Please try to kill and restart the app. If it doesn't fix the issue, please contact our Support",
        "title": "Something went wrong"
      },
      "tenant_expired": {
        "text": "Es tut uns leid, dieser Link ist gültig, aber wir haben einige Probleme bei der Bereitstellung. Bitte melden Sie den Fehlercode <b>402 - SDF - Tenant Expired</b> an die Person, die ihn Ihnen geschickt hat.",
        "title": "Dieser Link kann nicht geöffnet werden"
      }
    }
  }], ["en", {
    "callBar": {
      "actions": {
        "clearAnnotations": "Clear annotations",
        "hangUp": "Hang Up",
        "increaseZoom": "Increase Zoom",
        "more": "More",
        "participants": "Participants",
        "pauseRecording": "Pause recording",
        "recording": "Recording",
        "reduceZoom": "Reduce Zoom",
        "resumeRecording": "Resume recording",
        "settings": "Settings",
        "startCamera": "Start camera",
        "startFlashlight": "Start flashlight",
        "startMicrophone": "Start microphone",
        "startRecording": "Start recording",
        "startScreencast": "Start screencast",
        "stopCamera": "Stop camera",
        "stopFlashlight": "Stop flashlight",
        "stopMicrophone": "Stop microphone",
        "stopScreencast": "Stop screencast",
        "swapVideo": "Swap video",
        "switchCamera": "Switch camera",
        "videoZoom": "Zoom",
        "zoom": "Zoom"
      },
      "annotations": {
        "color": "Color",
        "label": "Annotations",
        "mode": "Mode",
        "shape": "Shape",
        "size": "Size"
      },
      "bottomSheet": {
        "localControls": "Local Controls",
        "remoteControls": "Remote Controls"
      },
      "participants": {
        "backAll": "Back to All Participants",
        "kickAll": "Kick All",
        "label": "Participants",
        "muteAll": "Mute All",
        "selectSwitchRHost": "Select the agent you want to promote as the Host",
        "selectSwitchRole": "Select the participant you want to promote as the Guest"
      },
      "recording": {
        "recordingActive": "This meeting is being recorded.",
        "recordingPaused": "The meeting recording is paused."
      },
      "remoteControls": "Remote </br> Controls",
      "remoteDeviceInfo": {
        "battery": "Battery",
        "device": "Device",
        "label": "Remote </br> Device Infos",
        "network": "Network"
      },
      "settings": {
        "audioSettings": "Audio Settings",
        "backCamera": "Back Camera",
        "camera": "Camera",
        "frontCamera": "Front Camera",
        "label": "Settings",
        "liveTranslation": {
          "enabled": "Enabled",
          "label": "Live Translation",
          "language": "Language",
          "subtitleSize": "Subtitle Size"
        },
        "microphone": "Microphone",
        "speaker": "Speaker",
        "videoSettings": "Video Settings",
        "virtualBackground": {
          "label": "Virtual Background",
          "mode": "Mode"
        }
      }
    },
    "chat": {
      "notifications": {
        "current": "Current meeting",
        "newMessage": "New message received",
        "now": "Now",
        "turnOff": "Message notifications enabled",
        "turnOn": "Message notifications disabled"
      },
      "welcome": "Welcome to Chat Room"
    },
    "close": "Close",
    "components": {
      "signature": {
        "clear": "Clear"
      }
    },
    "extensionInstall": {
      "installLabel": "Install",
      "instructions": {
        "Chrome": "Please install the SightCall Chrome extension from the Chrome Store.",
        "Edge": "Please install the SightCall Edge extension from the Edge Store.",
        "Firefox": "Please install the SightCall Firefox extension from the Firefox Store."
      },
      "logoAlt": {
        "Chrome": "Chrome logo",
        "Edge": "Edge logo",
        "Firefox": "Firefox logo"
      },
      "retryBtn": "Retry",
      "retryText": "You can click on the link below to try to restart the call",
      "title": {
        "Chrome": "Chrome Extension requested",
        "Edge": "Edge Extension requested",
        "Firefox": "Firefox Extension requested"
      }
    },
    "format": {
      "date": "MM/dd/yyyy",
      "hour": "h:mm a"
    },
    "imageEditor": {
      "arrow": "Arrow",
      "brightness": "Brightness",
      "brushWidth": "Brush",
      "circle": "Circle",
      "color": "Color",
      "contrast": "Contrast",
      "crop": "Crop",
      "fill": "Fill",
      "fontFamily": "Font Family",
      "helper": {
        "movePicture": {
          "android": "2 fingers",
          "ios": "2 fingers",
          "mac": "Option+Click",
          "windows": "Alt+Click"
        },
        "movePictureLabel": "Move Picture",
        "zoom": {
          "android": "Buttons (pinch in/out gesture not supported)",
          "ios": "Buttons (pinch in/out gesture not supported)",
          "mac": "Buttons + mouse wheel / trackpad",
          "windows": "Buttons + mouse wheel / trackpad"
        },
        "zoomLabel": "Zoom in/out"
      },
      "rectangle": "Rectangle",
      "rotate": "Rotate",
      "saturation": "Saturation",
      "stroke": "Stroke",
      "strokeWidth": "Stroke"
    },
    "newVersionModal": {
      "continueLabel": "Continue",
      "info": "\"Update\" to activate the new version.",
      "message": "\"Continue\" to ignore it.",
      "title": "New Version Available",
      "updateLabel": "Update",
      "warning": "All your data will be lost."
    },
    "open": "Open",
    "pluginInstall": {
      "Mac OS": {
        "Chrome": {
          "imgSrc": "/assets/images/plugin-install/chrome-1.png",
          "step1": "Open the '<em>'RTCCdriver.pkg'</em>' file at the bottom left of your browser to start the installation.",
          "title": "Download started to your browser"
        },
        "Firefox": {
          "imgSrc": "/assets/images/plugin-install/firefox-3.png",
          "step1": "Select '<em>'Open'</em>' with, and click on '<em>'OK'</em>'.",
          "title": "Plugin download started"
        },
        "Safari": {
          "imgSrc": "/assets/images/plugin-install/safari-3.png",
          "step1": "Click on the '<em>'download'</em>' icon at the top right of your browser main tab.",
          "step2": "Click on '<em>'RTCC_Plugin.pkg'</em>' file to start the installation.",
          "title": "Plugin download started"
        }
      },
      "Windows": {
        "Chrome": {
          "imgSrc": "/assets/images/plugin-install/chrome-2.png",
          "step1": "Open the '<em>'RTCCdriver.exe'</em>' file at the bottom left of your browser to start the installation.",
          "title": "Download started to your browser"
        },
        "Edge": {
          "imgSrc": "/assets/images/plugin-install/ie-1.png",
          "step1": "Click on '<em>'run'</em>', in the dialog box at the bottom, to start the installation.",
          "title": "Download and install RTCC Plugin"
        },
        "Firefox": {
          "imgSrc": "/assets/images/plugin-install/firefox-5.png",
          "step1": "Click on '<em>'Save File'</em>' to start the download of the Plugin.",
          "step2": "Click on the '<em>'download icon'</em>' <img class=\"icon-download\" src=\"/assets/images/plugin-install/firefox-4.png\" /> to pop up the Plugin file.",
          "step3": "Double-click on '<em>'RTCC Plugin.msi'</em>' to initiate the installation of the plugin.",
          "title": "Download and install RTCC Plugin"
        },
        "IE": {
          "imgSrc": "/assets/images/plugin-install/ie-1.png",
          "step1": "Click on '<em>'run'</em>', in the dialog box at the bottom, to start the installation.",
          "title": "Download and install RTCC Plugin"
        }
      },
      "followInstallationStep": "Follow the installation steps.",
      "retryBtn": "Retry",
      "retryText": "Once the plugin is installed and/or if the download did not start you can click on the button below",
      "step1": "Step1:",
      "step2": "Step2:",
      "step3": "Step3:",
      "step4": "Step4:"
    },
    "request": {
      "acceptLabel": "Accept",
      "cancelLabel": "Cancel",
      "canceledMessage": "The request has been canceled.",
      "closeLabel": "Close",
      "coreDump": {
        "title": "Copy coreDump to clipboard?"
      },
      "declineLabel": "Decline",
      "joinMeetingRoomCanceled": "{displayName} canceled his request to join the waiting room for {meetingTitle}",
      "joiningMeetingRoom": "{displayName} joined the waiting room for {meetingTitle}",
      "sharePicture": {
        "title": "Share picture from gallery?"
      },
      "startCamera": {
        "notifySent": "Your request to Start Camera has been sent",
        "title": "Start camera?"
      },
      "startGeolocation": {
        "title": "Share your location?"
      },
      "startMicro": {
        "notifySent": "Your request to Start Micro has been sent",
        "title": "Start microphone?"
      },
      "startRemoteControl": {
        "notifyDecline": "Your request to control remotely has been declined",
        "notifySent": "Your request to control remotely has been sent",
        "notifyUnavailable": "This user cannot be controlled remotely",
        "title": "Do you accept to give remote control of your device?"
      },
      "startShare": {
        "notifySent": "Your request to Start Screencast has been sent",
        "title": "Share screen?"
      },
      "switchCamera": {
        "frontTitle": "Switch to rear camera?",
        "notifySent": "Your request to Switch Camera has been sent",
        "rearTitle": "Switch to front camera?"
      },
      "switchHost": {
        "notifyDecline": "Your request to Switch Host has been declined",
        "notifySent": "Your request to Switch Host has been sent",
        "title": "Switch role to host?"
      },
      "switchRole": {
        "notifyDecline": "Your request to Switch Role has been declined",
        "notifySent": "Your request to Switch Role has been sent",
        "title": "Switch role to guest?"
      }
    },
    "resumeModal": {
      "expiry": "Expires on: '{{ formatDate \"${_expiresAt}\" \"MM/dd/yyyy hh:mm a\"}}'",
      "lastUpdate": "Last update on: '{{ formatDate \"${_lastUpdate}\" \"MM/dd/yyyy hh:mm a\"}}'",
      "message": "You already started this flow",
      "restartLabel": "Restart",
      "resumeLabel": "Resume",
      "title": "Flow Already Started"
    },
    "rtcc": {
      "videoDisabledLabel": "Video Disabled"
    },
    "services": {
      "barcodeScanner": {
        "cancelLabel": "Cancel",
        "confirmLabel": "Confirm"
      }
    },
    "today": "Today",
    "tooltips": {
      "copied": "Copied!"
    },
    "vs_screens": {
      "app_not_found": {
        "text": "Please visit the app store to download the latest version to execute SightCall Digital Flows.",
        "title": "You Need the Visual Support App"
      },
      "application_update_required": {
        "text": "Please visit the app store to download the latest version to execute SightCall Digital Flows.",
        "title": "Your Visual Support App Needs an Update"
      },
      "digital_flow_not_found": {
        "text": "We're sorry, the Digital Flow you requested wasn't found.",
        "title": "Digital Flow Not Found"
      },
      "digital_flow_not_valid": {
        "title": "Digital Flow definition is not valid"
      },
      "incompatible_device": {},
      "insufficient_credits": {
        "text": "We are sorry, this link is valid but we encounter some provisioning issues. Please report the error code <b>402 - SDF</b> to the person who sent it to you.",
        "title": "Unable to open this link"
      },
      "page_not_found": {
        "text": "We're sorry, the page you requested wasn't found.",
        "title": "Page Not Found"
      },
      "powered_by": "Powered By",
      "system_info_timeout": {
        "text": "The app took too long to send System information (timeout). Please try to kill and restart the app. If it doesn't fix the issue, please contact our Support",
        "title": "Something went wrong"
      },
      "tenant_expired": {
        "text": "We are sorry, this link is valid but we encounter some provisioning issues. Please report the error code <b>402 - SDF - Tenant Expired</b> to the person who sent it to you.",
        "title": "Unable to open this link"
      }
    }
  }], ["es", {
    "callBar": {
      "actions": {
        "clearAnnotations": "Borrar las anotaciones",
        "hangUp": "Colgar",
        "increaseZoom": "Aumentar Zoom",
        "more": "Más",
        "participants": "Participantes",
        "pauseRecording": "Pausar la grabación",
        "recording": "Grabación",
        "reduceZoom": "Reducir Zoom",
        "resumeRecording": "Retomar la grabación",
        "settings": "Configuraciones",
        "startCamera": "Iniciar la cámara",
        "startFlashlight": "Encender la linterna",
        "startMicrophone": "Iniciar el micrófono",
        "startRecording": "Iniciar la grabación",
        "startScreencast": "Iniciar el screencast",
        "stopCamera": "Detener la cámara",
        "stopFlashlight": "Apagar la linterna",
        "stopMicrophone": "Parar el micrófono",
        "stopScreencast": "Detener el screencast",
        "swapVideo": "Cambiar el vídeo",
        "switchCamera": "Cambiar la cámara",
        "videoZoom": "Zoom",
        "zoom": "Zoom"
      },
      "annotations": {
        "color": "Color",
        "label": "Anotaciones",
        "mode": "Modo",
        "shape": "Forma",
        "size": "Tamaño"
      },
      "bottomSheet": {
        "localControls": "Controles Locales",
        "remoteControls": "Controles Remotos"
      },
      "participants": {
        "backAll": "Volver a los participantes",
        "kickAll": "Kick All",
        "label": "Participantes",
        "muteAll": "Silenciar Todos",
        "selectSwitchRHost": "Seleccione el agente que desea promover como Host",
        "selectSwitchRole": "Seleccione el participante que desea promocionar como Guest"
      },
      "recording": {
        "recordingActive": "Esta reunión está siendo grabada.",
        "recordingPaused": "La grabación de la reunión está en pausa."
      },
      "remoteControls": "Controles </br> Remotos",
      "remoteDeviceInfo": {
        "battery": "Batería",
        "device": "Dispositivo",
        "label": "Información </br> Dispositivo",
        "network": "Red"
      },
      "settings": {
        "audioSettings": "Ajustes de Audio",
        "backCamera": "Cámara Trasera",
        "camera": "Cámara",
        "frontCamera": "Cámara Frontal",
        "label": "Ajustes",
        "liveTranslation": {
          "enabled": "Activada",
          "label": "Traducción en Directo",
          "language": "Idioma",
          "subtitleSize": "Tamaño de los Subtítulos"
        },
        "microphone": "Micrófono",
        "speaker": "Speaker",
        "videoSettings": "Ajustes de Vídeo",
        "virtualBackground": {
          "label": "Fondo Virtual",
          "mode": "Modo"
        }
      }
    },
    "chat": {
      "notifications": {
        "current": "Reunión actual",
        "newMessage": "Nuevo mensaje recibido",
        "now": "Ahora",
        "turnOff": "Notificaciones de mensajes activadas",
        "turnOn": "Notificaciones de mensajes desactivadas"
      },
      "welcome": "Bienvenido a la sala de chat"
    },
    "close": "Cerrar",
    "components": {
      "signature": {
        "clear": "Borrar"
      }
    },
    "extensionInstall": {
      "installLabel": "Install",
      "instructions": {
        "Chrome": "Por favor, instala la extensión SightCall para Chrome desde el Chrome Store.",
        "Edge": "Por favor, instala la extensión SightCall para Edge desde el Edge Store.",
        "Firefox": "Por favor, instala la extensión SightCall para Firefox desde el Firefox Store."
      },
      "logoAlt": {
        "Chrome": "Logo de Chrome",
        "Edge": "Logo de Edge",
        "Firefox": "Logo de Firefox"
      },
      "retryBtn": "Reintentar",
      "retryText": "Puede hacer clic en el link abajo para intentar reiniciar la llamada",
      "title": {
        "Chrome": "Extensión de Chrome solicitada",
        "Edge": "Extensión de Edge solicitada",
        "Firefox": "Extensión de Firefox solicitada"
      }
    },
    "format": {
      "date": "dd/MM/yyyy",
      "hour": "HH:mm"
    },
    "imageEditor": {
      "arrow": "Flecha",
      "brightness": "Brillo",
      "brushWidth": "Cepillo",
      "circle": "Círculo",
      "color": "Color",
      "contrast": "Contraste",
      "crop": "Cortar",
      "fill": "Rellene",
      "fontFamily": "Familia Fuente",
      "helper": {
        "movePicture": {
          "android": "2 dedos",
          "ios": "2 dedos",
          "mac": "Opción+Clic",
          "windows": "Alt+Clic"
        },
        "movePictureLabel": "Mover imagen",
        "zoom": {
          "android": "Botones (no admite el gesto de pellizcar para acercar o alejar)",
          "ios": "Botones (no admite el gesto de pellizcar para acercar o alejar)",
          "mac": "Botones + rueda del ratón / trackpad",
          "windows": "Botones + rueda del ratón / trackpad"
        },
        "zoomLabel": "Acercar/alejar"
      },
      "rectangle": "Rectángulo",
      "rotate": "Girar",
      "saturation": "Saturación",
      "stroke": "Trazo",
      "strokeWidth": "Trazo"
    },
    "newVersionModal": {
      "continueLabel": "Continuar",
      "info": "\"Actualizar\" para activar la nueva versión.",
      "message": "\"Continuar\" para ignorarlo.",
      "title": "Nueva Version Disponible",
      "updateLabel": "Actualizar",
      "warning": "Todos tus datos se perderán."
    },
    "open": "Abrir",
    "pluginInstall": {
      "Mac OS": {
        "Chrome": {
          "imgSrc": "/assets/images/plugin-install/chrome-1.png",
          "step1": "Abra el '<em>'RTCCdriver.pkg'</em>' archivo descargado en la parte inferior izquierda de su navegador.",
          "title": "Se ha iniciado la descarga"
        },
        "Firefox": {
          "imgSrc": "/assets/images/plugin-install/firefox-3.png",
          "step1": "Seleccionar '<em>'Abrir'</em>' con, y clic en '<em>'OK'</em>'.",
          "title": "Se ha iniciado la descarga del complemento"
        },
        "Safari": {
          "imgSrc": "/assets/images/plugin-install/safari-3.png",
          "step1": "Haga clic sobre el ícono de '<em>'download'</em>' en la parte superior derecha de su navegador.",
          "step2": "Haga clic sobre '<em>'RTCC_Plugin.pkg'</em>' el archivo descargado para iniciar la instalación.",
          "title": "Se ha iniciado la descarga del complemento"
        }
      },
      "Windows": {
        "Chrome": {
          "imgSrc": "/assets/images/plugin-install/chrome-2.png",
          "step1": "Abra el '<em>'RTCCdriver.exe'</em>' archivo descargado en la parte inferior izquierda de su navegador.",
          "title": "Se ha iniciado la descarga"
        },
        "Edge": {
          "imgSrc": "/assets/images/plugin-install/ie-1.png",
          "step1": "Haga clic sobre '<em>'ejecutar'</em>', en la ventana de diálogo a la izquierda para iniciar la instalación.",
          "title": "Descargar e instalar el complemento RTCC"
        },
        "Firefox": {
          "imgSrc": "/assets/images/plugin-install/firefox-5.png",
          "step1": "Haga clic sobre '<em>'Guardar Archivo'</em>' para iniciar la descarga del complemento.",
          "step2": "Haga clic sobre '<em>'download icon'</em>' <img class=\"icon-download\" src=\"/assets/images/plugin-install/firefox-4.png\" /> para abrir el complemento.",
          "step3": "Haga doble clic sobre '<em>'RTCC Plugin.msi'</em>' para inciar la instalación del complemento.",
          "title": "Descargar e instalar el complemento RTCC"
        },
        "IE": {
          "imgSrc": "/assets/images/plugin-install/ie-1.png",
          "step1": "Haga clic sobre '<em>'ejecutar'</em>', en la ventana de diálogo a la izquierda para iniciar la instalación.",
          "title": "Descargar e instalar el complemento RTCC"
        }
      },
      "followInstallationStep": "Siga los pasos de instalación.",
      "retryBtn": "Reintentar",
      "retryText": "Una vez instalado el plugin y/o si la descarga no se ha iniciado, puede hacer clic en el siguiente botón",
      "step1": "Paso 1:",
      "step2": "Paso 2:",
      "step3": "Paso 3:",
      "step4": "Paso 4:"
    },
    "request": {
      "acceptLabel": "Acepto",
      "cancelLabel": "Cancelar",
      "canceledMessage": "La solicitud ha sido cancelada.",
      "closeLabel": "Cerrar",
      "coreDump": {
        "title": "Copiar coreDump al portapapeles ?"
      },
      "declineLabel": "No Acepto",
      "joinMeetingRoomCanceled": "{displayName} canceló su solicitud para entrar en la sala de espera de {meetingTitle}",
      "joiningMeetingRoom": "{displayName} se unió a la sala de espera de {meetingTitle}",
      "sharePicture": {
        "title": "¿Compartir una imagen de la galería?"
      },
      "startCamera": {
        "notifySent": "Su solicitud para Iniciar cámara ha sido enviada",
        "title": "Iniciar cámara?"
      },
      "startGeolocation": {
        "title": "¿Compartir su ubicación?"
      },
      "startMicro": {
        "notifySent": "Su solicitud a Start Micro ha sido enviada",
        "title": "¿Iniciar el micrófono ?"
      },
      "startRemoteControl": {
        "notifyDecline": "Su solicitud de control remoto ha sido rechazada",
        "notifySent": "Su solicitud de control remoto ha sido enviada",
        "notifyUnavailable": "Este participante no puede controlarse a distancia",
        "title": "Aceptas dar el control remoto de tu dispositivo ?"
      },
      "startShare": {
        "notifySent": "Su solicitud para iniciar Screencast ha sido enviada",
        "title": "¿Compartir pantalla ?"
      },
      "switchCamera": {
        "frontTitle": "Cambiar a la cámara trasera ?",
        "notifySent": "Su solicitud para cambiar de cámara ha sido enviada",
        "rearTitle": "Cambiar a la cámara frontal ?"
      },
      "switchHost": {
        "notifyDecline": "Su solicitud para cambiar de Host ha sido rechazada",
        "notifySent": "Su solicitud para cambiar de Host ha sido enviada",
        "title": "Cambiar al rol de Host ?"
      },
      "switchRole": {
        "notifyDecline": "Su solicitud para cambiar de función ha sido rechazada",
        "notifySent": "Su solicitud para cambiar de función ha sido enviada",
        "title": "Cambiar al rol de Invitado ?"
      }
    },
    "resumeModal": {
      "expiry": "Expira el: '{{ formatDate \"${_expiresAt}\" \"dd/MM/yyyy HH:mm\"}}'",
      "lastUpdate": "Última actualización en: '{{ formatDate \"${_lastUpdate}\" \"dd/MM/yyyy HH:mm\"}}'",
      "message": "Ya iniciaste este flow",
      "restartLabel": "Reiniciar",
      "resumeLabel": "Resumir",
      "title": "Flow ya iniciado"
    },
    "rtcc": {
      "videoDisabledLabel": "Vídeo Deshabilitado"
    },
    "services": {
      "barcodeScanner": {
        "cancelLabel": "Cancelar",
        "confirmLabel": "Confirmar"
      }
    },
    "today": "Hoy",
    "tooltips": {
      "copied": "Copied!"
    },
    "vs_screens": {
      "app_not_found": {
        "text": "Please visit the app store to download the latest version to execute SightCall Digital Flows.",
        "title": "You Need the Visual Support App"
      },
      "application_update_required": {
        "text": "Please visit the app store to download the latest version to execute SightCall Digital Flows.",
        "title": "Your Visual Support App Needs an Update"
      },
      "digital_flow_not_found": {
        "text": "We're sorry, the Digital Flow you requested wasn't found.",
        "title": "Digital Flow Not Found"
      },
      "digital_flow_not_valid": {
        "title": "Digital Flow definicion no es valida"
      },
      "incompatible_device": {},
      "insufficient_credits": {
        "text": "We are sorry, this link is valid but we encounter some provisioning issues. Please report the error code <b>402 - SDF</b> to the person who sent it to you.",
        "title": "Unable to open this link"
      },
      "page_not_found": {
        "text": "We're sorry, the page you requested wasn't found.",
        "title": "Page Not Found"
      },
      "powered_by": "Powered By",
      "system_info_timeout": {
        "text": "The app took too long to send System information (timeout). Please try to kill and restart the app. If it doesn't fix the issue, please contact our Support",
        "title": "Something went wrong"
      },
      "tenant_expired": {
        "text": "We are sorry, this link is valid but we encounter some provisioning issues. Please report the error code <b>402 - SDF - Tenant Expired</b> to the person who sent it to you.",
        "title": "Unable to open this link"
      }
    }
  }], ["fr", {
    "callBar": {
      "actions": {
        "clearAnnotations": "Effacer les annotations",
        "hangUp": "Raccrocher",
        "increaseZoom": "Augmenter le zoom",
        "more": "Plus",
        "participants": "Participants",
        "pauseRecording": "Mettre l'enregistrement en pause",
        "recording": "Enregistrement",
        "reduceZoom": "Réduire le zoom",
        "resumeRecording": "Reprendre l'enregistrement",
        "settings": "Paramètres",
        "startCamera": "Démarrer la caméra",
        "startFlashlight": "Allumer le flash",
        "startMicrophone": "Activer le microphone",
        "startRecording": "Démarrer l'enregistrement",
        "startScreencast": "Démarrer le partage d'écran",
        "stopCamera": "Arrêter la caméra",
        "stopFlashlight": "Eteindre le flash",
        "stopMicrophone": "Arrêter le microphone",
        "stopScreencast": "Arrêter le partage d'écran",
        "swapVideo": "Changer la vidéo",
        "switchCamera": "Changer la caméra",
        "videoZoom": "Zoom",
        "zoom": "Zoom"
      },
      "annotations": {
        "color": "Couleur",
        "label": "Annotations",
        "mode": "Mode",
        "shape": "Forme",
        "size": "Taille"
      },
      "bottomSheet": {
        "localControls": "Contrôles Locaux",
        "remoteControls": "Contrôles à distance"
      },
      "participants": {
        "backAll": "Retour aux Participants",
        "kickAll": "Éjecter Tous",
        "label": "Participants",
        "muteAll": "Mute All",
        "selectSwitchRHost": "Sélectionnez l'agent que vous souhaitez promouvoir en tant qu'Hôte",
        "selectSwitchRole": "Sélectionnez le participant que vous souhaitez promouvoir en tant que Guest"
      },
      "recording": {
        "recordingActive": "Cette réunion est en cours d'enregistrement.",
        "recordingPaused": "L'enregistrement de la réunion est mis en pause."
      },
      "remoteControls": "Contrôles </br> à distance",
      "remoteDeviceInfo": {
        "battery": "Batterie",
        "device": "Appareil",
        "label": "Infos </br> Appareil",
        "network": "Réseau"
      },
      "settings": {
        "audioSettings": "Réglages audio",
        "backCamera": "Caméra Arrière",
        "camera": "Caméra",
        "frontCamera": "Caméra Avant",
        "label": "Réglages",
        "liveTranslation": {
          "enabled": "Activée",
          "label": "Traduction en Direct",
          "language": "Langue",
          "subtitleSize": "Taille des Sous-titres"
        },
        "microphone": "Micro",
        "speaker": "Speaker",
        "videoSettings": "Paramètres vidéo",
        "virtualBackground": {
          "label": "Fond Virtuel",
          "mode": "Mode"
        }
      }
    },
    "chat": {
      "notifications": {
        "current": "Réunion en cours",
        "newMessage": "Nouveau message reçu",
        "now": "Maintenant",
        "turnOff": "Notifications de messages activées",
        "turnOn": "Notifications de messages désactivées"
      },
      "welcome": "Bienvenue dans le salon de Chat"
    },
    "close": "Fermer",
    "components": {
      "signature": {
        "clear": "Effacer"
      }
    },
    "extensionInstall": {
      "installLabel": "Installer",
      "instructions": {
        "Chrome": "Veuillez installer l'extension SightCall Chrome depuis le Store Chrome.",
        "Edge": "Veuillez installer l'extension SightCall Edge depuis le Store Edge.",
        "Firefox": "Veuillez installer l'extension SightCall Firefox depuis le Store Firefox."
      },
      "logoAlt": {
        "Chrome": "Chrome logo",
        "Edge": "Edge logo",
        "Firefox": "Firefox logo"
      },
      "retryBtn": "Réessayer",
      "retryText": "Pour essayer de relancer l'appel vous pouvez cliquer sur le lien ci-dessous",
      "title": {
        "Chrome": "Extension Chrome requise",
        "Edge": "Extension Edge requise",
        "Firefox": "Extension Firefox requise"
      }
    },
    "format": {
      "date": "dd/MM/yyyy",
      "hour": "HH:mm"
    },
    "imageEditor": {
      "arrow": "Flèche",
      "brightness": "Luminosité",
      "brushWidth": "Pinceau",
      "circle": "Cercle",
      "color": "Couleur",
      "contrast": "Contraste",
      "crop": "Couper",
      "fill": "Remplir",
      "fontFamily": "Famille Police",
      "helper": {
        "movePicture": {
          "android": "2 doigts",
          "ios": "2 doigts",
          "mac": "Option+Clic",
          "windows": "Alt+Clic"
        },
        "movePictureLabel": "Déplacer l'image",
        "zoom": {
          "android": "Boutons (le geste d'entrée/sortie par pincement n'est pas pris en charge)",
          "ios": "Boutons (le geste d'entrée/sortie par pincement n'est pas pris en charge)",
          "mac": "Boutons + molette de la souris / trackpad",
          "windows": "Boutons + molette de la souris / trackpad"
        },
        "zoomLabel": "Zoom avant/arrière"
      },
      "rectangle": "Rectangle",
      "rotate": "Rotation",
      "saturation": "Saturation",
      "stroke": "Trait",
      "strokeWidth": "Trait"
    },
    "newVersionModal": {
      "continueLabel": "Continuer",
      "info": "\"Mettre à jour\" pour activer la nouvelle version.",
      "message": "\"Continuer\" pour l'ignorer.",
      "title": "Nouvelle Version Disponible",
      "updateLabel": "Mettre à jour",
      "warning": "Toutes vos données seront perdues."
    },
    "open": "Ouvrir",
    "pluginInstall": {
      "Mac OS": {
        "Chrome": {
          "imgSrc": "/assets/images/plugin-install/chrome-1.png",
          "step1": "Ouvrir le fichier '<em>'RTCCdriver.pkg'</em>' en bas à gauche de votre navigateur pour commencer l'installation.",
          "title": "Téléchargement du plugin en cours"
        },
        "Firefox": {
          "imgSrc": "/assets/images/plugin-install/firefox-3.png",
          "step1": "Sélectionner '<em>'Ouvrir avec'</em>', et cliquer sur '<em>'OK'</em>'.",
          "title": "Téléchargement du plugin en cours"
        },
        "Safari": {
          "imgSrc": "/assets/images/plugin-install/safari-3.png",
          "step1": "Cliquer sur l'icône de '<em>'téléchargement'</em>' en haut à droite de votre fenêtre principale.",
          "step2": "Cliquer sur '<em>'RTCC_Plugin.pkg'</em>' pour commencer l'installation.",
          "title": "Téléchargement du plugin en cours"
        }
      },
      "Windows": {
        "Chrome": {
          "imgSrc": "/assets/images/plugin-install/chrome-2.png",
          "step1": "Ouvrir le fichier '<em>'RTCCdriver.pkg'</em>' en bas à gauche de votre navigateur pour commencer l'installation.",
          "title": "Téléchargement du plugin en cours"
        },
        "Edge": {
          "imgSrc": "/assets/images/plugin-install/ie-1.png",
          "step1": "Cliquer sur '<em>'Exécuter'</em>', dans la barre de dialogue en bas pour commencer l'installation.",
          "title": "Télécharger et installer le Plugin RTCC"
        },
        "Firefox": {
          "imgSrc": "/assets/images/plugin-install/firefox-5.png",
          "step1": "Cliquer sur '<em>'Enregistrer'</em>' pour commencer le téléchargement du Plugin.",
          "step2": "Cliquer sur <img class=\"icon-download\" src=\"/assets/images/plugin-install/firefox-4.png\" /> pour faire apparaitre l'installeur.",
          "step3": "Double cliquer sur '<em>'RTCC Plugin.msi'</em>' pour commencer l'installation du Plugin.",
          "title": "Télécharger et installer le Plugin RTCC"
        },
        "IE": {
          "imgSrc": "/assets/images/plugin-install/ie-1.png",
          "step1": "Cliquer sur '<em>'Exécuter'</em>', dans la barre de dialogue en bas pour commencer l'installation.",
          "title": "Télécharger et installer le Plugin RTCC"
        }
      },
      "followInstallationStep": "Suivre les étapes d'intallation.",
      "retryBtn": "Réessayer",
      "retryText": "Une fois le plugin installé et/ou si le téléchargement ne s'est pas lancé vous pouvez cliquer sur le bouton ci-dessous",
      "step1": "Étape 1 :",
      "step2": "Étape 2 :",
      "step3": "Étape 3 :",
      "step4": "Étape 4 :"
    },
    "request": {
      "acceptLabel": "Accepter",
      "cancelLabel": "Annuler",
      "canceledMessage": "La demande a été annulée.",
      "closeLabel": "Fermer",
      "coreDump": {
        "title": "Copier les coreDump dans le presse-papiers ?"
      },
      "declineLabel": "Refuser",
      "joinMeetingRoomCanceled": "{displayName} a annulé sa requête pour rejoindre la salle d'attente de {meetingTitle}",
      "joiningMeetingRoom": "{displayName} a rejoint la salle d'attente pour le meeting {meetingTitle}",
      "sharePicture": {
        "title": "Partager l'image depuis la galerie ?"
      },
      "startCamera": {
        "notifySent": "Votre demande pour démarrer la caméra a été envoyée",
        "title": "Activer la caméra ?"
      },
      "startGeolocation": {
        "title": "Partager votre localisation ?"
      },
      "startMicro": {
        "notifySent": "Votre demande pour démarrer le micro a été envoyée",
        "title": "Activer le micro ?"
      },
      "startRemoteControl": {
        "notifyDecline": "Votre demande de contrôle à distance a été refusée",
        "notifySent": "Votre demande de contrôle à distance a été envoyée",
        "notifyUnavailable": "Cet utilisateur ne peut pas être contrôlé à distance",
        "title": "Acceptez-vous de donner le contrôle à distance de votre appareil ?"
      },
      "startShare": {
        "notifySent": "Votre demande pour démarrer le partage d'écran a été envoyée",
        "title": "Partager l'écran?"
      },
      "switchCamera": {
        "frontTitle": "Activer la caméra arrière ?",
        "notifySent": "Votre demande d'inversion de la caméra a été envoyée",
        "rearTitle": "Activer la caméra avant ?"
      },
      "switchHost": {
        "notifyDecline": "Votre demande pour changer de Host a été refusée",
        "notifySent": "Votre demande pour changer de Host a été envoyé",
        "title": "Changer pour le rôle de host ?"
      },
      "switchRole": {
        "notifyDecline": "Votre demande de changement de rôle a été refusée",
        "notifySent": "Votre demande de changement de rôle a été envoyée",
        "title": "Changer pour le rôle guest ?"
      }
    },
    "resumeModal": {
      "expiry": "Expire le : '{{ formatDate \"${_expiresAt}\" \"dd/MM/yyyy HH:mm\"}}'",
      "lastUpdate": "Dernière mise à jour le : '{{ formatDate \"${_lastUpdate}\" \"dd/MM/yyyy HH:mm\"}}'",
      "message": "Vous avez déjà commencé ce flow",
      "restartLabel": "Redémarrage",
      "resumeLabel": "Continuer",
      "title": "Flow déjà démarré"
    },
    "rtcc": {
      "videoDisabledLabel": "Vidéo Désactivée"
    },
    "services": {
      "barcodeScanner": {
        "cancelLabel": "Annuler",
        "confirmLabel": "Confirmer"
      }
    },
    "today": "Aujourd'hui",
    "tooltips": {
      "copied": "Copied!"
    },
    "vs_screens": {
      "app_not_found": {
        "text": "Please visit the app store to download the latest version to execute SightCall Digital Flows.",
        "title": "You Need the Visual Support App"
      },
      "application_update_required": {
        "text": "Please visit the app store to download the latest version to execute SightCall Digital Flows.",
        "title": "Your Visual Support App Needs an Update"
      },
      "digital_flow_not_found": {
        "text": "We're sorry, the Digital Flow you requested wasn't found.",
        "title": "Digital Flow Not Found"
      },
      "digital_flow_not_valid": {
        "title": "La définition du digital flow n'est pas valide."
      },
      "incompatible_device": {},
      "insufficient_credits": {
        "text": "We are sorry, this link is valid but we encounter some provisioning issues. Please report the error code <b>402 - SDF</b> to the person who sent it to you.",
        "title": "Unable to open this link"
      },
      "page_not_found": {
        "text": "We're sorry, the page you requested wasn't found.",
        "title": "Page Not Found"
      },
      "powered_by": "Powered By",
      "system_info_timeout": {
        "text": "The app took too long to send System information (timeout). Please try to kill and restart the app. If it doesn't fix the issue, please contact our Support",
        "title": "Something went wrong"
      },
      "tenant_expired": {
        "text": "We are sorry, this link is valid but we encounter some provisioning issues. Please report the error code <b>402 - SDF - Tenant Expired</b> to the person who sent it to you.",
        "title": "Unable to open this link"
      }
    }
  }], ["it", {
    "callBar": {
      "actions": {
        "clearAnnotations": "Cancellare le annotazioni",
        "hangUp": "Riagganciare",
        "increaseZoom": "Aumenta Zoom",
        "more": "Altro",
        "participants": "Partecipanti",
        "pauseRecording": "Mettere in pausa la registrazione",
        "recording": "Registrazione",
        "reduceZoom": "Riduci Zoom",
        "resumeRecording": "Riprendere la registrazione",
        "settings": "Impostazioni",
        "startCamera": "Avviare la telecamera",
        "startFlashlight": "Avviare la torcia",
        "startMicrophone": "Accendere il microfono",
        "startRecording": "Avviare la registrazione",
        "startScreencast": "Avviare lo screencast",
        "stopCamera": "Fermare la telecamera",
        "stopFlashlight": "Fermare la torcia",
        "stopMicrophone": "Interrompere il microfono",
        "stopScreencast": "Interrompere lo screencast",
        "swapVideo": "Scambiare il video",
        "switchCamera": "Commutare la telecamera",
        "videoZoom": "Zoom",
        "zoom": "Zoom"
      },
      "annotations": {
        "color": "Colore",
        "label": "Annotazioni",
        "mode": "Modo",
        "shape": "Forma",
        "size": "Dimensione"
      },
      "bottomSheet": {
        "localControls": "Controlli locali",
        "remoteControls": "Controlli remoti"
      },
      "participants": {
        "backAll": "Torna a Tutti i partecipanti",
        "kickAll": "Calci a tutti",
        "label": "Partecipanti",
        "muteAll": "Silenziare tutti",
        "selectSwitchRHost": "Selezionare l'agente che si desidera promuovere come Host",
        "selectSwitchRole": "Selezionare il partecipante che si desidera promuovere come Guest"
      },
      "recording": {
        "recordingActive": "Questa riunione viene registrata.",
        "recordingPaused": "La registrazione della riunione viene messa in pausa."
      },
      "remoteControls": "Controlli </br> remoti",
      "remoteDeviceInfo": {
        "battery": "Batteria",
        "device": "Dispositivo",
        "label": "Informazioni </br> sul dispositivo",
        "network": "Rete"
      },
      "settings": {
        "audioSettings": "Impostazioni audio",
        "backCamera": "Fotocamera posteriore",
        "camera": "Fotocamera",
        "frontCamera": "Fotocamera anteriore",
        "label": "Impostazioni",
        "liveTranslation": {
          "enabled": "Abilitata",
          "label": "Traduzione dal vivo",
          "language": "Lingua",
          "subtitleSize": "Dimensione dei Sottotitoli"
        },
        "microphone": "Microfono",
        "speaker": "Speaker",
        "videoSettings": "Impostazioni video",
        "virtualBackground": {
          "label": "Sfondo virtuale",
          "mode": "Modo"
        }
      }
    },
    "chat": {
      "notifications": {
        "current": "Incontro in corso",
        "newMessage": "Nuovo messaggio ricevuto",
        "now": "Ora",
        "turnOff": "Notifiche dei messaggi attivate",
        "turnOn": "Notifiche dei messaggi disattivate"
      },
      "welcome": "Benvenuto nella chat room"
    },
    "close": "Chiudi",
    "components": {
      "signature": {
        "clear": "Pulire"
      }
    },
    "extensionInstall": {
      "installLabel": "Installare",
      "instructions": {
        "Chrome": "Per favore installa l'estensione SightCall Chrome dal Chrome Store.",
        "Edge": "Si prega di installare l'estensione SightCall Edge dal Edge Store.",
        "Firefox": "Si prega di installare l'estensione SightCall Firefox dal Firefox Store."
      },
      "logoAlt": {
        "Chrome": "logo Chrome",
        "Edge": "Logo Edge",
        "Firefox": "logo Firefox"
      },
      "retryBtn": "Riprovare",
      "retryText": "È possibile fare clic sul link sottostante per provare a riavviare la chiamata",
      "title": {
        "Chrome": "estensione Chrome richiesta",
        "Edge": "Estensione di Edge richiesta",
        "Firefox": "Estensione Firefox richiesta"
      }
    },
    "format": {
      "date": "dd/MM/yyyy",
      "hour": "HH:mm"
    },
    "imageEditor": {
      "arrow": "Freccia",
      "brightness": "Luminosità",
      "brushWidth": "Spazzola",
      "circle": "Cerchio",
      "color": "Colore",
      "contrast": "Contrasto",
      "crop": "Ritagliare",
      "fill": "Riempimento",
      "fontFamily": "Famiglia Caratteri",
      "helper": {
        "movePicture": {
          "android": "2 dita",
          "ios": "2 dita",
          "mac": "Opzione+clic",
          "windows": "Alt+Click"
        },
        "movePictureLabel": "Spostare l'immagine",
        "zoom": {
          "android": "Pulsanti (il gesto del pinch in/out non è supportato)",
          "ios": "Pulsanti (il gesto del pinch in/out non è supportato)",
          "mac": "Pulsanti + rotellina del mouse / trackpad",
          "windows": "Pulsanti + rotellina del mouse / trackpad"
        },
        "zoomLabel": "Zoom avanti/indietro"
      },
      "rectangle": "Rettangolo",
      "rotate": "Ruotare",
      "saturation": "Saturazione",
      "stroke": "Corsa",
      "strokeWidth": "Corsa"
    },
    "newVersionModal": {
      "continueLabel": "Continua",
      "info": "\"Aggiorna\" per attivare la nuova versione.",
      "message": "\"Continua\" per ignorarlo.",
      "title": "Nuova Versione Disponibile",
      "updateLabel": "Aggiorna",
      "warning": "Tutti i tuoi dati andranno perduti."
    },
    "open": "Aprire",
    "pluginInstall": {
      "Mac OS": {
        "Chrome": {
          "imgSrc": "/assets/images/plugin-install/chrome-1.png",
          "step1": "Apri il file '<em>'RTCCdriver.pkg'</em>' in basso a sinistra del tuo browser per iniziare l'installazione.",
          "title": "Download del plugin in corso"
        },
        "Firefox": {
          "imgSrc": "/assets/images/plugin-install/firefox-3.png",
          "step1": "Seleziona '<em>'Apri con '</em>', e clicca su '<em>'OK'</em>'.",
          "title": "Download del plugin in corso"
        },
        "Safari": {
          "imgSrc": "/assets/images/plugin-install/safari-3.png",
          "step1": "Clicca sull'icona '<em>'download'</em>' nell'angolo in alto a destra della tua finestra principale.",
          "step2": "Clicca su '<em>'RTCC_Plugin.pkg'</em>' per iniziare l'installazione.",
          "title": "Download del plugin in corso"
        }
      },
      "Windows": {
        "Chrome": {
          "imgSrc": "/assets/images/plugin-install/chrome-2.png",
          "step1": "Apri il file '<em>'RTCCdriver.pkg'</em>' in basso a sinistra del tuo browser per iniziare l'installazione.",
          "title": "Download del plugin in corso"
        },
        "Edge": {
          "imgSrc": "/assets/images/plugin-install/ie-1.png",
          "step1": "Clicca su '<em>'Execute'</em>', nella barra di dialogo in basso per avviare l'installazione.",
          "title": "Scaricare e installare il plugin RTCC"
        },
        "Firefox": {
          "imgSrc": "/assets/images/plugin-install/firefox-5.png",
          "step1": "Clicca su '<em>'Salva'</em>' per iniziare a scaricare il plugin.",
          "step2": "Clicca <img class=\"icon-download\" src=\"/assets/images/plugin-install/firefox-4.png\" /> per far apparire il programma di installazione.",
          "step3": "Doppio clic su '<em>'RTCC Plugin.msi'</em>' per iniziare l'installazione del plugin.",
          "title": "Scaricare e installare il plugin RTCC"
        },
        "IE": {
          "imgSrc": "/assets/images/plugin-install/ie-1.png",
          "step1": "Click on '<em>'run'</em>', in the dialog box at the bottom, to start the installation.",
          "title": "Download and install RTCC Plugin"
        }
      },
      "followInstallationStep": "FollowInstallationStep.",
      "retryBtn": "Riprovare",
      "retryText": "Una volta che il plugin è stato installato e/o se il download non si è avviato, è possibile fare clic sul pulsante seguente",
      "step1": "Passo 1:",
      "step2": "Passo 2:",
      "step3": "Passo 3:",
      "step4": "Passo 4:"
    },
    "request": {
      "acceptLabel": "Accettare",
      "cancelLabel": "Annullare",
      "canceledMessage": "La richiesta è stata annullata.",
      "closeLabel": "Chiudere",
      "coreDump": {
        "title": "Copiare coreDump negli appunti ?"
      },
      "declineLabel": "Rifiuttare",
      "joinMeetingRoomCanceled": "{displayName} ha annullato la sua richiesta di unirsi alla sala d'attesa per {meetingTitle}",
      "joiningMeetingRoom": "{displayName} si unì alla sala d'attesa per {meetingTitle}",
      "sharePicture": {
        "title": "Condividere un'immagine dalla galleria?"
      },
      "startCamera": {
        "notifySent": "La tua richiesta di avvio della fotocamera è stata inviata",
        "title": "Attivare la telecamera ?"
      },
      "startGeolocation": {
        "title": "Condividere la propria posizione ?"
      },
      "startMicro": {
        "notifySent": "La tua richiesta a Start Micro è stata inviata",
        "title": "Attivare il micro ?"
      },
      "startRemoteControl": {
        "notifyDecline": "La richiesta di controllo a distanza è stata rifiutata",
        "notifySent": "La richiesta di controllo a distanza è stata inviata",
        "notifyUnavailable": "Questo utente non può essere controllato a distanza",
        "title": "Accetti di dare il controllo remoto del tuo dispositivo ?"
      },
      "startShare": {
        "notifySent": "La tua richiesta per avviare Screencast è stata inviata",
        "title": "Condividere lo schermo ?"
      },
      "switchCamera": {
        "frontTitle": "Attivare la telecamera posteriore ?",
        "notifySent": "La tua richiesta di cambiare fotocamera è stata inviata",
        "rearTitle": "Attivare la telecamera anteriore ?"
      },
      "switchHost": {
        "notifyDecline": "La tua richiesta di passare al ruolo di ospite è stata rifiutata",
        "notifySent": "La tua richiesta di passare al ruolo di ospite è stata inviata",
        "title": "Passare al ruolo di ospite ?"
      },
      "switchRole": {
        "notifyDecline": "La tua richiesta di cambiare ruolo è stata rifiutata",
        "notifySent": "La tua richiesta di cambiare ruolo è stata inviata",
        "title": "Passare al ruolo di ospite ?"
      }
    },
    "resumeModal": {
      "expiry": "Scade il: '{{ formatDate \"${_expiresAt}\" \"dd/MM/yyyy HH:mm\"}}'",
      "lastUpdate": "Ultimo aggiornamento su: '{{ formatDate \"${_lastUpdate}\" \"dd/MM/yyyy HH:mm\"}}'",
      "message": "Hai già avviato questo flow",
      "restartLabel": "Ricomincia",
      "resumeLabel": "Riprendere",
      "title": "Flow già avviato"
    },
    "rtcc": {
      "videoDisabledLabel": "Video Disattivato"
    },
    "services": {
      "barcodeScanner": {
        "cancelLabel": "Annullare",
        "confirmLabel": "Confermare"
      }
    },
    "today": "Oggi",
    "tooltips": {
      "copied": "Copied!"
    },
    "vs_screens": {
      "app_not_found": {
        "text": "Please visit the app store to download the latest version to execute SightCall Digital Flows.",
        "title": "You Need the Visual Support App"
      },
      "application_update_required": {
        "text": "Please visit the app store to download the latest version to execute SightCall Digital Flows.",
        "title": "Your Visual Support App Needs an Update"
      },
      "digital_flow_not_found": {
        "text": "We're sorry, the Digital Flow you requested wasn't found.",
        "title": "Digital Flow Not Found"
      },
      "digital_flow_not_valid": {
        "title": "Digital Flow definizione non è valida"
      },
      "incompatible_device": {},
      "insufficient_credits": {
        "text": "We are sorry, this link is valid but we encounter some provisioning issues. Please report the error code <b>402 - SDF</b> to the person who sent it to you.",
        "title": "Unable to open this link"
      },
      "page_not_found": {
        "text": "We're sorry, the page you requested wasn't found.",
        "title": "Page Not Found"
      },
      "powered_by": "Powered By",
      "system_info_timeout": {
        "text": "The app took too long to send System information (timeout). Please try to kill and restart the app. If it doesn't fix the issue, please contact our Support",
        "title": "Something went wrong"
      },
      "tenant_expired": {
        "text": "We are sorry, this link is valid but we encounter some provisioning issues. Please report the error code <b>402 - SDF - Tenant Expired</b> to the person who sent it to you.",
        "title": "Unable to open this link"
      }
    }
  }], ["pt", {
    "callBar": {
      "actions": {
        "clearAnnotations": "Apagar as anotações",
        "hangUp": "Desligar",
        "increaseZoom": "Aumentar Zoom",
        "more": "Mais",
        "participants": "Participantes",
        "pauseRecording": "Pausar a gravação",
        "recording": "Gravação",
        "reduceZoom": "Reduzir Zoom",
        "resumeRecording": "Retomar a gravação",
        "settings": "Configurações",
        "startCamera": "Ligar a câmera",
        "startFlashlight": "Ligar o flash",
        "startMicrophone": "Ligar o microfone",
        "startRecording": "Iniciar a gravação",
        "startScreencast": "Iniciar o screencast",
        "stopCamera": "Desligar a câmera",
        "stopFlashlight": "Desligar o flash",
        "stopMicrophone": "Desligar o microfone",
        "stopScreencast": "Parar o screencast",
        "swapVideo": "Trocar o vídeo",
        "switchCamera": "Mudar a câmera",
        "videoZoom": "Zoom",
        "zoom": "Zoom"
      },
      "annotations": {
        "color": "Cor",
        "label": "Anotações",
        "mode": "Modo",
        "shape": "Forma",
        "size": "Tamanho"
      },
      "bottomSheet": {
        "localControls": "Controles Locais",
        "remoteControls": "Controles Remotos"
      },
      "participants": {
        "backAll": "Voltar para os participantes",
        "kickAll": "Excluir Todos",
        "label": "Participantes",
        "muteAll": "Silenciar Todos",
        "selectSwitchRHost": "Selecione o agente que você deseja promover como Host",
        "selectSwitchRole": "Selecione o participante que você deseja promover como Guest"
      },
      "recording": {
        "recordingActive": "Esta reunião está sendo gravada.",
        "recordingPaused": "A gravação da reunião é pausada."
      },
      "remoteControls": "Controles </br> Remotos",
      "remoteDeviceInfo": {
        "battery": "Bateria",
        "device": "Dispositivo",
        "label": "Informações </br>  Dispositivo",
        "network": "Rede"
      },
      "settings": {
        "audioSettings": "Configurações de áudio",
        "backCamera": "Câmera traseira",
        "camera": "Câmera",
        "frontCamera": "Câmera Frontal",
        "label": "Configurações",
        "liveTranslation": {
          "enabled": "Ativada",
          "label": "Tradução ao vivo",
          "language": "Idioma",
          "subtitleSize": "Tamanho da Legenda"
        },
        "microphone": "Microfone",
        "speaker": "Speaker",
        "videoSettings": "Configurações de Vídeo",
        "virtualBackground": {
          "label": "Fundo Virtual",
          "mode": "Modo"
        }
      }
    },
    "chat": {
      "notifications": {
        "current": "Reunião atual",
        "newMessage": "Nova mensagem recebida",
        "now": "Agora",
        "turnOff": "Notificações de mensagens ativadas",
        "turnOn": "Notificações de mensagens desativadas"
      },
      "welcome": "Bem-vindo à sala de chat"
    },
    "close": "Fechar",
    "components": {
      "signature": {
        "clear": "Limpar"
      }
    },
    "extensionInstall": {
      "installLabel": "Install",
      "instructions": {
        "Chrome": "Instale a extensão SightCall Chrome da Chrome Store.",
        "Edge": "Instale a extensão SightCall Edge da Edge Store.",
        "Firefox": "Instale a extensão SightCall Firefox na loja Firefox."
      },
      "logoAlt": {
        "Chrome": "Logotipo do Chrome",
        "Edge": "Logotipo do Edge",
        "Firefox": "Logotipo do Firefox"
      },
      "retryBtn": "Tentar de novo",
      "retryText": "Pode clicar no link abaixo para tentar reiniciar a chamada",
      "title": {
        "Chrome": "Extensão do Chrome solicitada",
        "Edge": "Extensão do Edge solicitada",
        "Firefox": "Extensão do Firefox solicitada"
      }
    },
    "format": {
      "date": "dd/MM/yyyy",
      "hour": "HH:mm"
    },
    "imageEditor": {
      "arrow": "Flecha",
      "brightness": "Brilho",
      "brushWidth": "Pincel",
      "circle": "Círculo",
      "color": "Cor",
      "contrast": "Contraste",
      "crop": "Cortar",
      "fill": "Preencher",
      "fontFamily": "Família Fonte",
      "helper": {
        "movePicture": {
          "android": "2 dedos",
          "ios": "2 dedos",
          "mac": "Opção+Clique",
          "windows": "Alt+Clique"
        },
        "movePictureLabel": "Mover imagem",
        "zoom": {
          "android": "Botões (o gesto de aproximar/afastar não é suportado)",
          "ios": "Botões (o gesto de aproximar/afastar não é suportado)",
          "mac": "Botões + roda do mouse / trackpad",
          "windows": "Botões + roda do mouse / trackpad"
        },
        "zoomLabel": "Aumentar/reduzir o zoom"
      },
      "rectangle": "Retângulo",
      "rotate": "Girar",
      "saturation": "Saturação",
      "stroke": "Linha",
      "strokeWidth": "Linha"
    },
    "newVersionModal": {
      "continueLabel": "Continuar",
      "info": "\"Atualizar\" para ativar a nova versão.",
      "message": "\"Continuar\" para ignorá-lo.",
      "title": "Nova Versão Disponível",
      "updateLabel": "Atualizar",
      "warning": "Todos os seus dados serão perdidos."
    },
    "open": "Abrir",
    "pluginInstall": {
      "Mac OS": {
        "Chrome": {
          "imgSrc": "/assets/images/plugin-install/chrome-1.png",
          "step1": "Clique no arquivo '<em>'RTCCdriver.pkg'</em>' no canto inferior esquerdo do seu navegador para iniciar a instalação",
          "title": "O Plugin começou a baixar"
        },
        "Firefox": {
          "imgSrc": "/assets/images/plugin-install/firefox-3.png",
          "step1": "Selecione '<em>'Abrir com'</em>' e clique em '<em>'OK'</em>'",
          "title": "O Plugin começou a baixar"
        },
        "Safari": {
          "imgSrc": "/assets/images/plugin-install/safari-3.png",
          "step1": "Clique no ícone '<em>'Download'</em>' no canto superior direito do seu navegador",
          "step2": "Clique no arquivo '<em>'RTCC_Plugin.pkg'</em>' para iniciar a instalação",
          "title": "O Plugin começou a baixar"
        }
      },
      "Windows": {
        "Chrome": {
          "imgSrc": "/assets/images/plugin-install/chrome-2.png",
          "step1": "Clique no arquivo '<em>'RTCCdriver.exe'</em>' no canto inferior esquerdo do seu navegador para iniciar a instalação",
          "title": "O Plugin começou a baixar"
        },
        "Edge": {
          "imgSrc": "/assets/images/plugin-install/ie-1.png",
          "step1": "Clique em '<em>'executar'</em>', na caixa de diálogo no canto inferior esquerdo do seu navegador para iniciar a instalação",
          "title": "Faça o download e instale o Plugin RTCC"
        },
        "Firefox": {
          "imgSrc": "/assets/images/plugin-install/firefox-5.png",
          "step1": "Clique em '<em>'Salvar Arquivo'</em>' para baixar o Plugin",
          "step2": "Clique no ícone '<em>'Download'</em>' <img class=\"icon-download\" src=\"/assets/images/plugin-install/firefox-4.png\" /> para abrir o arquivo Plugin",
          "step3": "Dê um duplo clique em '<em>'Plugin RTCC.msi'</em>' para iniciar a instalação do Plugin",
          "title": "Baixe e instale o Plugin RTCC"
        },
        "IE": {
          "imgSrc": "/assets/images/plugin-install/ie-1.png",
          "step1": "Clique em '<em>'executar'</em>', na caixa de diálogo no canto inferior esquerdo do seu navegador para iniciar a instalação",
          "title": "Faça o download e instale o Plugin RTCC"
        }
      },
      "followInstallationStep": "Siga os passos da instalação.",
      "retryBtn": "Tentar de novo",
      "retryText": "Uma vez instalado o plugin e/ou se o download não tiver sido iniciado, pode clicar no botão abaixo",
      "step1": "Passo 1:",
      "step2": "Passo 2:",
      "step3": "Passo 3:",
      "step4": "Passo 4:"
    },
    "request": {
      "acceptLabel": "Aceitar",
      "cancelLabel": "Cancelar",
      "canceledMessage": "A solicitação foi cancelada.",
      "closeLabel": "Fechar",
      "coreDump": {
        "title": "Copiar os coreDump para prancheta ?"
      },
      "declineLabel": "Declinar",
      "joinMeetingRoomCanceled": "{displayName} cancelou a sua solitação sua solicitação para entrar na sala de espera para {meetingTitle}",
      "joiningMeetingRoom": "{displayName} entrou na sala de espera para o meeting {meetingTitle}",
      "sharePicture": {
        "title": "¿Compartir una imagen de la galería?"
      },
      "startCamera": {
        "notifySent": "Sua solicitação para iniciar a câmera foi enviada",
        "title": "Iniciar camera ?"
      },
      "startGeolocation": {
        "title": "Compartilhar a sua localização ?"
      },
      "startMicro": {
        "notifySent": "Sua solicitação para Start Micro foi enviada",
        "title": "Iniciar microfone ?"
      },
      "startRemoteControl": {
        "notifyDecline": "Sua solicitação de controle remoto foi recusada",
        "notifySent": "Sua solicitação para controlar remotamente foi enviada",
        "notifyUnavailable": "Este utilizador não pode ser controlado remotamente",
        "title": "Você aceita ceder o controle do seu dispositivo ?"
      },
      "startShare": {
        "notifySent": "Sua solicitação para iniciar o Screencast foi enviada",
        "title": "Compartilhar tela ?"
      },
      "switchCamera": {
        "frontTitle": "Mudar para a câmera traseira ?",
        "notifySent": "Sua solicitação para trocar de câmera foi enviada",
        "rearTitle": "Mudar para a câmera frontal ?"
      },
      "switchHost": {
        "notifyDecline": "Sua solicitação para mudar de Host foi recusada",
        "notifySent": "Sua solicitação para trocar de Host foi enviada",
        "title": "Cambiar al rol de Host ?"
      },
      "switchRole": {
        "notifyDecline": "Sua solicitação para trocar de função foi recusada",
        "notifySent": "Sua solicitação para mudar de função foi enviada",
        "title": "Mudar o papel de convidado ?"
      }
    },
    "resumeModal": {
      "expiry": "Expira em: '{{ formatDate \"${_expiresAt}\" \"dd/MM/yyyy HH:mm\"}}'",
      "lastUpdate": "Última atualização em: '{{ formatDate \"${_lastUpdate}\" \"dd/MM/yyyy HH:mm\"}}'",
      "message": "Você já iniciou este flow",
      "restartLabel": "Reiniciar",
      "resumeLabel": "Retomar",
      "title": "Flow já iniciado"
    },
    "rtcc": {
      "videoDisabledLabel": "Vídeo Desativado"
    },
    "services": {
      "barcodeScanner": {
        "cancelLabel": "Cancelar",
        "confirmLabel": "Confirmar"
      }
    },
    "today": "Hoje",
    "tooltips": {
      "copied": "Copied!"
    },
    "vs_screens": {
      "app_not_found": {
        "text": "Please visit the app store to download the latest version to execute SightCall Digital Flows.",
        "title": "You Need the Visual Support App"
      },
      "application_update_required": {
        "text": "Please visit the app store to download the latest version to execute SightCall Digital Flows.",
        "title": "Your Visual Support App Needs an Update"
      },
      "digital_flow_not_found": {
        "text": "We're sorry, the Digital Flow you requested wasn't found.",
        "title": "Digital Flow Not Found"
      },
      "digital_flow_not_valid": {
        "title": "Digital Flow definição não é válida"
      },
      "incompatible_device": {},
      "insufficient_credits": {
        "text": "We are sorry, this link is valid but we encounter some provisioning issues. Please report the error code <b>402 - SDF</b> to the person who sent it to you.",
        "title": "Unable to open this link"
      },
      "page_not_found": {
        "text": "We're sorry, the page you requested wasn't found.",
        "title": "Page Not Found"
      },
      "powered_by": "Powered By",
      "system_info_timeout": {
        "text": "The app took too long to send System information (timeout). Please try to kill and restart the app. If it doesn't fix the issue, please contact our Support",
        "title": "Something went wrong"
      },
      "tenant_expired": {
        "text": "We are sorry, this link is valid but we encounter some provisioning issues. Please report the error code <b>402 - SDF - Tenant Expired</b> to the person who sent it to you.",
        "title": "Unable to open this link"
      }
    }
  }]];
});